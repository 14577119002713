import { useState, useEffect, useRef } from 'react'
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import ReactPlayer from 'react-player'
import Slider, { slickGoTo } from "react-slick";
import { useHover } from "@uidotdev/usehooks";
import axios from 'axios'

import Nav from 'react-bootstrap/Nav';
import { Navbar, Container } from 'react-bootstrap';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Facebook, Instagram,TelephoneFill,Youtube,Linkedin,Tiktok, Whatsapp, Envelope, ArrowLeft, ArrowRight } from 'react-bootstrap-icons';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'
import Marquee from "react-fast-marquee";
import Accordion from 'react-bootstrap/Accordion';
import { isMobile } from 'react-device-detect';
import PhotoAlbum from "react-photo-album";

import logo from './img/logo.png';
import logoFooter from './img/logo-footer.png';
import logoWhats from './img/whats.png';
import mebs from './img/MEBS.png'

import location from './img/location.png'
import paloma from './img/paloma.png'
import left from './img/left.png'
import right from './img/right.png'
import services from './img/services.png'
import clientLogos from './img/client-logos.png'
import line from './img/line.png'
import shape1 from './img/squart-1.jpg'
import shape2 from './img/squart-2.jpg'

import insta from './img/insta.png'
import face from './img/face.png'
import instab from './img/instab.png'
import faceb from './img/faceb.png'

import proyect1 from './img/project1.jpg'
import proyect2 from './img/project2.jpg'
import proyect3 from './img/project3.jpg'

import video from './img/home.jpg'
import step1 from './img/step1.png'
import step2 from './img/step2.png'
import step3 from './img/step3.png'
import step4 from './img/step4.png'
import step5 from './img/step5.png'
import step6 from './img/step6.png'

import './App.css';
import 'animate.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const host = 'https://afirmativo.mx/'
//const host = 'http://localhost:3000/'
//const host = 'http://qa.mgpublicidad.mx/'

const PROJECTS = [ proyect1, proyect2, proyect3 ];

const STEPS = [
  {
    img: step1,
    title: 'Consulta Inicial',
    desc: 'Reunión para comprender objetivos y necesidades específicas del proyecto.'
  },
  {
    img: step2,
    title: 'Análisis de Espacio y Necesidades',
    desc: 'Evaluamos el espacio y recopilamos información para un diseño adecuado.'
  },
  {
    img: step3,
    title: 'Propuesta de Diseño',
    desc: 'Presentamos planos y renders detallados para mejorar funcionalidad y estética.'
  },
  {
    img: step4,
    title: 'Desarrollo y Ejecución',
    desc: 'Gestionamos la construcción, coordinando aspectos técnicos y logísticos.'
  },
  {
    img: step5,
    title: 'Entrega y Seguimiento',
    desc: 'Entregamos el proyecto terminado y proporcionamos seguimiento post-entrega.'
  }
]

var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: isMobile ? 2 : 3,
  slidesToScroll: 1,
  autoplay: false,
  //autoplaySpeed: 2000,
  dots: false,
  arrows: false,
  pauseOnHover: false,
  pauseOnFocus: false,
  pauseOnDotsHover: false
};

function App() {
  const [goingUp, setGoingUp] = useState(false)
  const prevScrollY = useRef(0)

  const [isLoading, setLoading] = useState(false)
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [comments, setComments] = useState("")

  const [showMessage, setShowMessage] = useState(false)
  const [title, setTitle] = useState('')
  const [message, setMessage] = useState('')

  const refHome = useRef()
  const refUs = useRef()
  const refServices = useRef()
  const refProjects = useRef()
  const refContact = useRef()

  const [show, setShow] = useState(false);
  const [ slideIndex, setSlideIndex ] = useState(0)

  let sliderRef = useRef(null);

  useEffect(() => {
    if( window.location.pathname == '/gracias' ) {
      setLoading(false)
      setTitle("Mensaje enviado")
      setMessage("Tu mensaje ha sido enviado. En breve nos comunicaremos contigo. Gracias.");
      handleShow()
    }
  })

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);
 
      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }
 
      if (isScriptExist && callback) callback();
    }
 
    // load the script by passing the URL
    loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=6LdCWnsnAAAAAPzMX6aHkNNvs4a47SN1jI6ptYbM`, function () {
      console.log("Script loaded!");
    });
  }, []);

  useEffect(() => {

    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      var prevScrollYTemp = prevScrollY.current;
      
      if (prevScrollY.current < currentScrollY && goingUp) {
        setGoingUp(false);
      }
      if (prevScrollY.current > currentScrollY && !goingUp) {
        setGoingUp(true);
      }

      prevScrollY.current = currentScrollY;

      if(currentScrollY > 10) {
        document.getElementById("mg-navbar").className = "bg-header navbar navbar-expand-lg navbar-dark fixed-top";
      } else {
        document.getElementById("mg-navbar").className = "bg-header-start navbar navbar-expand-lg navbar-dark fixed-top";
      }

    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [goingUp]);

  const handleClick = (eventKey) => {

    var element = eval(eventKey).current;
    var headerOffset = 130;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });

  };

  const handleShow = () => setShowMessage(true);
  const handleOnSubmit = (event) => {
    event.preventDefault();
    
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute("6LdCWnsnAAAAAPzMX6aHkNNvs4a47SN1jI6ptYbM", { action: 'submit' }).then(token => {
        //if(isVerified){
        setLoading(true);
        //const recaptchaValue = recaptchaRef.current.getValue();
        var form = document.getElementById("contactForm");
        var formData = new FormData(form);
        formData.append('recaptcha_response', token);

        setLoading(true);
        axios.post("https://inmobiplace.com/api/contactAfirmativo/", formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
          }).then(function(response) {

            if(response.data == "FAIL" || response.data == "ERROR") {
              setLoading(false)
              setTitle("Error")
              setMessage("Ocurrió un error al intentar enviar tu mensaje, por favor intenta mas tarde.");
              handleShow()
            } else {
              window.location.href = "/gracias"
            }

          }).catch(function(err) {
            setLoading(false)
            setTitle("Error")
            setMessage("Ocurrió un error al intentar enviar tu mensaje, por favor intenta mas tarde.");
            handleShow()
          });
      });
    });
  }

  const closeModalSent = () => {
    if( title == 'Error') setShowMessage(false)
    else window.location.href = "/"
  }

  const handleOnChangeSlider = ( operation ) => {
    if( slideIndex === 0 && operation === -1 ) setSlideIndex( slideIndex )
    else if( slideIndex === (PROJECTS.length - 1) && operation === 1 ) setSlideIndex( slideIndex )
    else if ( operation === -1 ) {
      setSlideIndex( slideIndex - 1 )
      sliderRef.slickGoTo( slideIndex - 1 )
    } else {
      setSlideIndex( slideIndex + 1 )
      sliderRef.slickGoTo( slideIndex + 1 )
    }
  }

  return (
    <>

      <Modal show={showMessage} onHide={ () => closeModalSent() }>
        <Modal.Header style={{ background: "#fff" }} closeButton>
          <Modal.Title style={{ color: "#000" }}>{ title }</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ color: "#000", background: "#fff" }}>{ message }</Modal.Body>
        <Modal.Footer style={{ color: "#000", background: "#fff" }}>
          <Button variant="secondary" onClick={ () => closeModalSent() }>
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="App">
        <FloatingWhatsApp 
          phoneNumber="5215567020600"
          accountName="Espacio Vital"  
          className="wa-style"
          statusMessage="En linea"
          avatar={ logoWhats }
          chatMessage="Hola, ¡Bienvenido a Espacio Vital!. ¿Cómo podemos ayudarte?"
          allowClickAway
          notification={false}
        />
          <header className='header'>
        <Container >
      <Navbar id="mg-navbar" collapseOnSelect expand="lg" className={ "bg-header-start" } variant="dark" fixed="top">
        <Container>
              <Navbar.Brand href="/">
                <img src={logo} height="100" className="d-inline-block" />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                
                <Nav className="me-auto"></Nav>
                
                <Nav onSelect={ handleClick } className='align-items-center'>
                  <Nav.Link eventKey="refUs" className="nav-link-comp font-heder">
                    Nosotros
                  </Nav.Link>
                  {/* <Nav.Link eventKey="refBook" className="nav-link font-heder">
                    Book  
                  </Nav.Link>*/}
                  <Nav.Link eventKey="refServices" className="nav-link font-heder">
                    Servicios
                  </Nav.Link>
                  <Nav.Link eventKey="refProjects" className="nav-link font-heder">
                    Proyectos
                  </Nav.Link>
                  <Nav.Link eventKey="refContact" className="nav-link font-heder nav-btn">
                    Contacto
                  </Nav.Link>
                  
                </Nav>

              </Navbar.Collapse>
              
            </Container>
          </Navbar>
        </Container>
        </header>
        
        <div ref={refHome} style={{ position: 'relative' }}>
          {/* <ReactPlayer className="player-v" url='af.mp4' playing={ true } loop={ true } volume={ 0 } muted={ true } controls={ true } /> */}
          <img src={video} width={ '100%' }/>
          <div className='float-container'>
            <img src={ insta } className='float-icon'/>&nbsp;&nbsp;
            <img src={ face } className='float-icon'/>
          </div>
        </div>
        <div className='section' ref={refUs}>
          <Container>
            <Row className='align-items-center'>
              <Col md={ 12 }xs={ 12 } align="center">
                <div className='description-us-container'>
                  <div className='description-us-title-1'> NUESTRO </div>
                  <div className='description-us-title-2'> PROCESO DE TRABAJO </div>
                  <div className='description-us-title-3'> CONSISTE EN 5 SENCILLOS PASOS PARA CREAR UN MUNDO A TU MEDIDA </div>
                </div>
              </Col>
            </Row>
            <br/><br/><br/>
            <Row className='align-items-center'>
              { STEPS.slice(0, 3).map( item => (
                <Col md={ 4 } style={{ marginBottom: 30, padding: 0 }}>
                  <div>
                    <Container>
                      <Row className='align-items-center'>
                        <Col md={ 'auto' }>
                          <div><img src={ item.img } className='step-icon'/></div>
                        </Col>
                        <Col style={{ padding: 0 }}>
                          <Container className='step-card'>
                            <Row className='align-items-center step-row'>
                              <Col style={{ padding: 0 }}>
                                <div className='step-title'>{ item.title }</div><br/>
                                <div className='step-desc'>{ item.desc }</div>
                              </Col>
                            </Row>
                          </Container>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </Col>
              ))}
            </Row>

            <Row className='align-items-center'>
              <Col md={ 2 } style={{ marginBottom: 30, padding: 0 }}></Col>
              { STEPS.slice(3, 5).map( item => (
                <Col md={ 4 } style={{ marginBottom: 30, padding: 0 }}>
                  <div>
                    <Container>
                      <Row className='align-items-center'>
                        <Col md={ 'auto' }>
                          <div><img src={ item.img } className='step-icon'/></div>
                        </Col>
                        <Col style={{ padding: 0 }}>
                          <Container className='step-card'>
                            <Row className='align-items-center step-row'>
                              <Col style={{ padding: 0 }}>
                                <div className='step-title'>{ item.title }</div><br/>
                                <div className='step-desc'>{ item.desc }</div>
                              </Col>
                            </Row>
                          </Container>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </Col>
              ))}
              <Col md={ 2 } style={{ marginBottom: 30, padding: 0 }} align='center'>
                <div> <img src={ step6 } className='step-icon-end'/> </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className='section'>
          <Container>
            <Row className='align-items-center'>
              <Col md={ 7 }>
                <div align='center'>
                  <div className='info-background'></div>
                </div>
              </Col>
              <Col md={ 5 }>
                <Container className='info-card'>
                  <Row className='align-items-center'>
                    <Col>
                      <div className='info-title'> SOMOS UN <b>ESPACIO VITAL</b> </div>
                      <div className='info-subtitle'> Y UNA OPORTUNIDAD PARA <b>CREAR EXPERIENCIAS</b> ÚNICAS </div>
                      <div className='info-desc'>que atraigan a tus clientes. Especializados en diseño, arquitectura, construcción y decoración de interiores, transformamos locales comerciales en ambientes excepcionales, materializando tu visión para potenciar tu negocio.</div>
                      <br/>
                      <div align='center'>
                        <div className='info-btn'> 
                          <div className='info-btn-text-1'> CONOCE NUESTROS </div>  
                          <div className='info-btn-text-2'> PROYECTOS </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </div>

        <div ref={ refProjects } className='section section-round'>
          <div className='project-style'>
            <div className='back-shapes-1'> <img src={ shape1 } height={'100%'}/> </div>
            <div className='back-shapes-2'> <img src={ shape2 } height={'100%'}/> </div>
            <Container>
              <Row className='align-items-center'>
                <Col md={ 12 }xs={ 12 } align="center" style={{ padding: 0 }}>
                  <div className='project-container-background'>
                    <div className='project-container'>
                      <div className='description-us-title-1'> PORTAFOLIO </div>
                      <div className='description-us-title-2'> CONOCE NUESTROS PROYECTOS </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <br/>
          <br/>
          <Container fluid>
            <Row className='align-items-center'>
              <Col md={ 12 }xs={ 12 } align="center" style={{ padding: 0 }}>
                <Slider ref={slider => { sliderRef = slider; }} {...settings}>
                  { PROJECTS.map( item => (
                    <div>
                      <img src={ item } className='service-carousel-img' />
                    </div>
                  )) }
                </Slider>
                <br/>
                <div align='center'>
                  <span onClick={ () => handleOnChangeSlider( -1 )}>
                    <img src={ left } className='arrow-nav' />
                  </span> &nbsp;&nbsp;&nbsp;
                  <span onClick={ () => handleOnChangeSlider( 1 )}>
                    <img src={ right } className='arrow-nav'/>
                  </span>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        

        
        <div ref={ refServices } className='section clients-section'>
          <Container>
            <br/><br/>
            <Row>
              <Col>
                <div align='center'>
                  <img src={ services } className='service-title'/>
                </div>
                <div align='center'> 
                  <div className='pleca'> Bienvenido a tu Espacio Vital, líder en remodelación y diseño de interiores comerciales. Descubre cómo podemos convertir tu visión en una realidad cautivadora. </div>
                </div>
              </Col>
            </Row>
            <br/><br/><br/><br/> <br/><br/>
            <Row className='align-items-center'>
              <Col md={ 6 }>
                  <div className='clients-container'>
                    <Container>
                      <Row>
                        <Col>
                          <div className='clients-title '> NUESTROS CLIENTES </div>
                        </Col>
                      </Row>
                      <div style={{ height: 100 }}/>
                      <Row>
                        <Col>
                          <div> <img src={ clientLogos } className='client-logo' /> </div>
                        </Col>
                      </Row>
                      <div style={{ height: 100 }}/>
                    </Container>
                  </div>
              </Col>
              <Col md={ 6 }>
                <div className='clients-bullets'>
                  <ul>
                    <li className='li-space'> Diseño y Arquitectura </li>
                    <li className='li-space'> Remodelación/Construcción integral </li>
                    <li className='li-space'> Diseño de interiores </li>
                    <li className='li-space'> Asesoramiento en decoración </li>
                    <li className='li-space'> Gestión de proyectos llave en mano </li>
                    <li className='li-space'> Mobiliario </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
          <br/><br/>
        </div>

        <div ref={refContact} className='footer section'>
        <div align="center">
          <Container>
            <Row className='align-items-center'>
              <Col md={ 6 } align="center">
                <div className='footer-subtitle'> NUESTRO </div>
                <div className='footer-title'> EQUIPO </div>
                <br/>
                <div> <img src={ line } className='line-style' /> </div>
                <br/>
                <div className='footer-desc'>
                  Con un equipo de arquitectos, diseñadores de interiores y expertos en remodelación, nos comprometemos a ofrecer soluciones innovadoras y de calidad para cada proyecto.
                </div>
              </Col>
              <Col md={ 6 }>
                <div className='form-container'>
                  <Form id="contactForm" onSubmit={ handleOnSubmit }>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label></Form.Label>
                        <Form.Control type="text" className='form-footer' placeholder="Nombre completo *" name='name' value={ name } onChange={ () => setName()}/><br/>
                        <Form.Control type="email" className='form-footer' placeholder="Correo electrónico *" name='email' value={ email } onChange={ () => setEmail()}/><br/>
                        <Form.Control type="number" className='form-footer' placeholder="Teléfono *" name='phone' value={ phone } onChange={ () => setPhone()}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                      <Form.Control className='form-footer' placeholder="¿Cuándo planeas desarrollar tu proyecto? *" rows={3} name='comments' value={ comments } onChange={ () => setComments()}/>
                    </Form.Group>
                      <div align="center"><Button disabled={isLoading} className="button-footer" type="submit" >{isLoading ? 'ENVIANDO...' : 'ENVIAR'}</Button></div>
                      <br />
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      <div style={{ marginBottom: '30px' }}>
        <Container fluid>
          <br/>
          <br/>
          <Row className='line-container'>
            <div className='line-footer'></div>
          </Row>
          <br/>
          <Row className='align-items-center line-container' align="center">
            <Col md={2} onClick={ () => handleClick('refHome') } style={{ cursor: 'pointer' }}>
              { isMobile && <br/> }
              <div><img src={ logoFooter }className='footer-logo'/></div>
              </Col>
            <Col md={8} style={{ cursor: 'pointer' }} onClick={ () => setShow(true) }>
              <div align="center" className='footer-policy'>Aviso de privacidad</div>
              <div align="center" className='footer-rights'>© 2024. TODOS LOS DERECHOS RESERVADOS</div>
              </Col>
            <Col md={2}> 
              <div align='center' style={{ marginBottom: '10px' }}>
                <img src={ instab } className='float-icon'/>&nbsp;&nbsp;
                <img src={ faceb } className='float-icon'/>
              </div>
               <div align="center" className='footer-policy'>@ESPACIO VITAL </div>
            </Col>
          </Row>
          
        </Container>
      </div>

    </div>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body className="footer-privacity">
          <br/>
          <p>GRUPO MEBS, S.A. DE C.V., (en lo sucesivo MEBS), con domicilio en Jos&eacute; Guadalupe Posada n&uacute;mero 16, colonia Ciudad Sat&eacute;lite, Naucalpan de Ju&aacute;rez, C.P. 53100, Estado de M&eacute;xico es el responsable del uso y protecci&oacute;n de sus datos personales, y al respecto le informamos lo siguiente:&nbsp;</p>

            <p>&nbsp;</p>

            <p>Los datos personales que en su caso nos proporcione, los utilizaremos para las siguientes finalidades:&nbsp;</p>

            <p>&nbsp;</p>

            <ol>
              <li>Proveerle los servicios o productos que comercializamos.</li>
              <li>Brindarle los servicios que nos solicite.</li>
              <li>Conocer sus necesidades de productos o servicios para estar en posibilidad de ofrecerle los que m&aacute;s se adecuen a sus preferencias.</li>
              <li>Enviar por cualquier medio f&iacute;sico o electr&oacute;nico (correos electr&oacute;nicos, mensajes de texto, redes sociales, mensajer&iacute;a instant&aacute;nea, etc.), conocido o por conocerse, informaci&oacute;n de car&aacute;cter comercial, de mercadeo y promocional sobre los servicios o promociones desarrolladas por MEBS, sus empresas filiales, subsidirias y/o relacionadas, as&iacute; como de sus socios comerciales y/o proveedores.</li>
              <li>Identificaci&oacute;n y contacto.</li>
              <li>Fines estad&iacute;sticos.</li>
              <li>Elaboraci&oacute;n de bases de datos.</li>
              <li>Atender quejas y aclaraciones, y en su caso, tratarlos para fines compatibles con los mencionados en este Aviso de privacidad y que se consideren an&aacute;logos para efectos legales.</li>
            </ol>

            <p>&nbsp;</p>

            <p>Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, podremos solicitarle los siguientes datos personales:</p>

            <ol>
              <li>Nombre.</li>
              <li>Tel&eacute;fono.</li>
              <li>Correo electr&oacute;nico.</li>
            </ol>

            <p>MEBS no recaba Datos Personales Sensibles. No obstante, si se diera el caso, se obtendr&aacute; el consentimiento expreso y por escrito del titular para su tratamiento, a trav&eacute;s de su firma aut&oacute;grafa, firma electr&oacute;nica o cualquier mecanismo de autenticaci&oacute;n.</p>

            <p>&nbsp;</p>

            <p>Le informamos que sus datos personales pueden ser compartidos dentro y fuera del pa&iacute;s para las finalidades antes mencionadas, &uacute;nicamente con empresas filiales, subsidirias y/o relacionadas con MEBS.</p>

            <p>&nbsp;</p>

            <p>Usted acepta y reconoce que las transferencias anteriormente descritas son necesarias para cumplir con las obligaciones que tiene pactadas MEBS con usted por lo que no se requiere su consentimiento para realizar las mismas.</p>

            <p>&nbsp;</p>

            <p>Asimismo, le informamos que para el desarrollo de algunos servicios, productos y/o promociones MEBS podr&aacute; requerir la participaci&oacute;n de socios comerciales y/o proveedores a los cuales se les podr&aacute; dar acceso a nuestras bases de datos para finalidades especificas, en el entendido que en todo momento MEBS conservar&aacute; la propiedad de dichas bases de datos.</p>

            <p>&nbsp;</p>

            <p>Usted tiene derecho a conocer que datos personales tenemos de usted, para que los utilizamos y las condiciones del uso que les damos (Acceso).&nbsp;</p>

            <p>&nbsp;</p>

            <p>&nbsp;</p>

            <p>&nbsp;</p>

            <p>Asimismo, es su derecho solicitar la correcci&oacute;n de su informaci&oacute;n personal en caso de que estar desactualizada, sea inexacta o incompleta (Rectificaci&oacute;n); que la eliminemos de nuestros registros o bases de datos cuando considere que la misma no este siendo utilizada adecuadamente (Cancelaci&oacute;n) as&iacute; como oponerse al uso de sus datos personales para fines especif&iacute;cos (Oposici&oacute;n). Estos derechos se conocen como Derechos ARCO.</p>

            <p>&nbsp;</p>

            <p>Para revocar su consentimiento para el tratamiento de sus datos personales y el ejercicio de sus Derechos ARCO, deber&aacute; presentar una solicitud (la &ldquo;Solicitud ARCO&rdquo;), la cual deber&aacute; enviar al correo electr&oacute;nico contacto@grupomebs.com, acompa&ntilde;ada de la siguiente informaci&oacute;n y documentaci&oacute;n:</p>

            <p>&nbsp;</p>

            <ul>
              <li>Su nombre, domicilio y correo electr&oacute;nico para poder comunicarle la respuesta a la Solicitud ARCO.</li>
              <li>Los documentos que acrediten su identidad (copia de IFE, pasaporte o cualquier otra identificaci&oacute;n oficial) o en su caso,los documentos que acrediten su representaci&oacute;n legal.</li>
              <li>Una descripci&oacute;n clara y precisa de los datos personales respecto de los cuales busca ejercer alguno de los Derechos ARCO.</li>
              <li>Cualquier documento o informaci&oacute;n que facilite la localizaci&oacute;n de sus datos personales.</li>
              <li>En caso de solicitar una rectificaci&oacute;n de datos, deber&aacute; de indicar tambi&eacute;n, las modificaciones a realizarse y aportar ladocumentaci&oacute;n que sustente su petici&oacute;n, y la indicaci&oacute;n del lugar donde se podr&aacute;n revisar los originales de la documentaci&oacute;n que acompa&ntilde;e.</li>
            </ul>

            <p>&nbsp;</p>

            <p>MEBS responder&aacute; su Solicitud ARCO y expresar&aacute;&nbsp; los motivos de su decisi&oacute;n mediante un correo electr&oacute;nico en un plazo m&aacute;ximo de 20 d&iacute;as naturales contados desde el d&iacute;a en que se haya recibido su Solicitud ARCO.&nbsp;</p>

            <p>&nbsp;</p>

            <p>En caso de que la Solicitud ARCO se conteste de manera afirmativa o procedente, los cambios solicitados se har&aacute;n en un plazo m&aacute;ximo de 15 d&iacute;as naturales.&nbsp;</p>

            <p>&nbsp;</p>

            <p>Los plazos antes referidos se podr&aacute;n prorrogar por una sola vez por un periodo igual en caso de ser necesario.</p>

            <p>&nbsp;</p>

            <p>MEBS podr&aacute; negar el acceso para que usted ejerza los Derechos ARCO en los siguientes supuestos:</p>

            <p>&nbsp;</p>

            <ul>
              <li>Cuando Usted no sea el titular de los datos personales, o no pueda acreditar la representaci&oacute;n del titular.</li>
              <li>Cuando sus datos personales no obren en las bases de datos de MEBS.</li>
              <li>Cuando se lesionen los derechos de un tercero.</li>
              <li>Cuando exista un impedimento legal o la resoluci&oacute;n de una autoridad competente, que restrinja sus Derechos ARCO, y cuando la rectificaci&oacute;n, cancelaci&oacute;n u oposici&oacute;n haya sido previamente realizada.</li>
            </ul>

            <p>&nbsp;</p>

            <p>La negativa podr&aacute; ser parcial, en cuyo caso MEBS efectuar&aacute; el acceso, rectificaci&oacute;n, cancelaci&oacute;n u oposici&oacute;n en la parte procedente.</p>

            <p>En el caso de revocar el consentimiento que&nbsp; nos haya otorgado para el tratamiento de sus datos personales es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna obligaci&oacute;n legal requiramos seguir tratando sus datos personales. Asimismo, usted deber considerar que para ciertos fines, la revocaci&oacute;n de su consentimiento implicar&aacute; que no le podamos seguir prestando servicios.</p>

            <p>&nbsp;</p>

            <p>&nbsp;</p>

            <p>Con el objetivo de mejorar la experiencia de sus usuarios en sus sitios web, MEBS podr&aacute; utilizar &ldquo;cookies&rdquo; y/o &ldquo;web beacons&rdquo;. Las &quot;cookies&quot; son archivos de texto que son descargados autom&aacute;ticamente y almacenados en el disco duro del equipo de c&oacute;mputo del usuario al navegar en una p&aacute;gina de Internet para recordar algunos datos sobre este usuario, entre ellos, sus preferencias para la visualizaci&oacute;n de las p&aacute;ginas en ese servidor, nombre y contrase&ntilde;a. Por su parte, las &quot;web beacons&quot; son im&aacute;genes insertadas en un p&aacute;gina de Internet o correo electr&oacute;nico, que puede ser utilizado para monitorear el comportamiento de un visitante, como almacenar informaci&oacute;n sobre la direcci&oacute;n IP del usuario, duraci&oacute;n del tiempo de interacci&oacute;n en dicha p&aacute;gina y el tipo de navegador utilizado, entre otros. Le informamos que utilizamos &quot;cookies&quot; y &quot;web beacons&quot; con fines &uacute;nicamente de marketing, experiencia de usuario y medici&oacute;n, para obtener informaci&oacute;n como la siguiente:</p>

            <p>&nbsp;</p>

            <ul>
              <li>Su tipo de navegador y sistema operativo;</li>
              <li>Las p&aacute;ginas de Internet que visita;</li>
              <li>Los v&iacute;nculos que sigue;</li>
              <li>La direcci&oacute;n IP; y el sitio que visit&oacute; antes de entrar al nuestro.</li>
            </ul>

            <p>&nbsp;</p>

            <p>Estas cookies y otras tecnolog&iacute;as pueden ser deshabilitadas. Para conocer c&oacute;mo hacerlo, consulte el men&uacute; de ayuda de su navegador. Tenga en cuenta que, en caso de desactivar las &quot;cookies&quot;, es posible que no pueda acceder a ciertas funciones personalizadas en nuestro sitio web.</p>

            <p>&nbsp;</p>

            <p>El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos; de nuestras praticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas.</p>

            <p>&nbsp;</p>

            <p>El sitio de internet https://grupomebs.com/ puede contener enlaces a otros sitios web, respecto de los cuales MEBS no se hace responsable de las pr&aacute;cticas y usos de los otros sitios, incluyendo los de nuestros patrocinadores y/o socios comerciales</p>

            <p>&nbsp;</p>

            <p>Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad, a trav&eacute;s de nuestro sitio web https://grupomebs.com/.</p>

            <p>&nbsp;</p>

            <p>El Responsable cuenta con medidas de seguridad f&iacute;sicas, administrativas y t&eacute;cnicas para resguardar sus Datos Personales. No obstante, en caso de que ocurra alguna vulneraci&oacute;n a la seguridad que afecte de forma significativa sus derechos patrimoniales o morales, el Responsable lo har&aacute; de su conocimiento mediante el correo electr&oacute;nico que proporcion&oacute;, a fin de que usted pueda tomar las medidas necesarias correspondientes para la defensa de los mismos.</p>

            <p>&nbsp;</p>

            <p>En el momento que usted se registra en nuestro portal o nos proporciona sus datos personales usted manifiesta su consentimiento expreso para el tratamiento de sus datos personales de conformidad con este Aviso de Privacidad.</p>

        </Modal.Body>
      </Modal>

    </>
  );
}

export default App;
